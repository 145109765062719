<template>
  <div h="full" w="full" class="absolute" flex="~ col">
    <div v-if="isShowAppBar" h="10" bg="white" flex="~ items-center" p="x-3" class="relative z-1 shadow-[0_2px_8px_0px_rgba(122,122,122,0.25)]">
      <NuxtIcon name="my-icon:navigator" text="lg black" @click="back" />
      <span flex="1" text="center" class="truncate" p="l-10 r-12">{{ title }}</span>
    </div>
    <div v-if="!isMobile" :style="{ backgroundColor: isHome ? 'var(--tc-color-bg-index)' : 'var(--tc-color-bg-list)' }">
      <BusinessBUserHeader class="mx-auto max-w-300" />
    </div>
    <div id="scroll-container" ref="scroll-page-ref" flex="1" bg="#ffffff" overflow="y-auto">
      <slot></slot>
      <!-- 底部介绍 -->
      <MobileFooter v-if="isMobile" />
      <DesktopFooter v-else />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Intercom from '@intercom/messenger-js-sdk'
import { useTitle } from '@vueuse/core'

// 切换页面时滚动到顶部
const scrollPageRef = useTemplateRef('scroll-page-ref')
const nuxtApp = useNuxtApp()
nuxtApp.hook('page:finish', () => {
  scrollPageRef.value?.scrollTo(0, 0)
})

await usePageConfig()

const route = useRoute()
const { back } = useRouter()
const title = useTitle('', { observe: true })

const isHome = computed(() => route.path === '/' || route.path === '/attractions')
const isMobile = useMobileByScreen()
const isShowAppBar = computed(() => isMobile.value && route.query.campaign_id)

onMounted(() => {
  Intercom({
    app_id: 'iy1e1izd',
    vertical_padding: 60,
    horizontal_padding: 40,
    language_override: 'en',
  })
})
</script>

<style>
.intercom-app {
  position: absolute!important;
  /* z-index: 0 !important; */
}

/* .intercom-lightweight-app {
  z-index: 0 !important;
} */

@media screen and (max-width: 450px) {
  .intercom-launcher {
    bottom: 60px !important;
  }
  div[class^="intercom-with-namespace-"] {
    bottom: 60px !important;
  }
}
</style>
